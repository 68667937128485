<template>
  <div>
    <div
      class="row"
      v-show="!isLoading.groups && groups.length === 0"
    >
      <div class="flex xs12">
        <h5>{{ $t('users.tabs.groups.nothing_to_add') }}</h5>
      </div>
    </div>
    <div class="row">
      <div
        class="flex xs12 sm6 md4"
        v-for="group in groups"
        :key="group.id"
      >
        <va-checkbox
          :label="getLabel(group.name)"
          :disabled="loading || isLoading.groups"
          v-model="group.isMember"
        />
      </div>
    </div>
    <div
      class="row"
      v-show="groups.length > 0"
    >
      <div class="flex xs12">
        <va-button
          color="primary"
          :disabled="loading || isLoading.groups"
          @click="submit"
        >
          <text-loading
            :loading="loading"
            icon="fa fa-save"
          >
            {{ $t('layout.form.save') }}
          </text-loading>
        </va-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'change-group',
  props: {
    loading: {
      type: Boolean,
      default: function () { return false },
    },
    user: {
      type: Object,
      required: false,
      default: function () { return {} },
    },
  },
  data () {
    return {
      isLoading: {
        groups: false,
      },
      groups: [],
    }
  },
  watch: {
    user (val) {
      if (val.groups && this.groups.length !== 0) {
        this.groups = this.fixData(this.groups)
      }
    },
  },
  created () {
    this.getGroups()
  },
  methods: {
    fixData (data) {
      const groups = []
      for (const d of data) {
        const group = {
          id: d.id,
          name: d.name,
          isMember: false,
        }
        group.isMember = this.userIn(d.id)
        groups.push(group)
      }
      return groups
    },
    userIn (groupId) {
      if (!this.user || !this.user.groups) return false
      const isIn = this.user.groups.find(g => g.id === groupId)

      return isIn !== undefined
    },
    async getGroups () {
      this.isLoading.groups = true

      let u = false
      try {
        u = await this.$http.get('users/groups')
      } catch (err) {
        // console.log('Error fetching user data', err)
        this.isLoading.groups = false
        return
      }

      this.isLoading.groups = false
      this.groups = this.fixData(u.data.data)
    },
    async submit () {
      const isValid = this.groups.some(g => g.isMember === true)
      if (!isValid) return

      const groups = this.groups.filter(g => g.isMember)
      const userGroups = {
        id: this.user.id,
        groups: {
          _ids: groups.map(g => g.id),
        },
      }

      this.$emit('submit', userGroups)
    },
    getLabel (label) {
      return this.$t('groups.' + label)
    },
  },
}
</script>
